<template>
    <div>
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules">
                    <el-form-item label="厂商名称:" prop="name" :label-width="formLabelWidth">
                        <el-input v-model="diaform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="厂商类型:" prop="type" :label-width="formLabelWidth">
                        <el-select v-model="diaform.type" placeholder="请选择">
                            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="diaform.remark">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',

            diaform: {
                name: '',
                type: '',
                remark: ''
            },
            dialogVisible: false,
            typeOptions: [
                {
                    value: 0,
                    label: 'GPS设备'
                },
                {
                    value: 1,
                    label: '客流设备'
                },
                {
                    value: 2,
                    label: 'DVR'
                },
                {
                    value: 3,
                    label: 'POS机'
                }
            ],
            formLabelWidth: '100px',

            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入厂商名称',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请选择厂商类型',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
            }
        }
    },
    activated() {},
    methods: {
        // 打开对话框
        init(option, type) {
            this.dialogVisible = true
            this.type = type || 'add'
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
                    this.diaform.type = this.diaform.type - 0
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/basedevicevendor/save'
                    } else {
                        url = '/basedevicevendor/update'
                    }

                    let option = this.diaform
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>